import 'keen-slider/keen-slider.min.css'

import styled from '@emotion/styled'
import { FadeInUp } from 'app/components/Common/Animation/FadeInUp'
import { Button } from 'app/components/Common/Button'
import { Cursor } from 'app/components/Common/Cursor'
import { Image, Props as ImageProps } from 'app/components/Common/Image'
import { FlexBox } from 'app/components/Layout/FlexBox'
import { useVocabularyData } from 'app/utils/vocabulary'
import { useKeenSlider } from 'keen-slider/react'
import { uniq } from 'lodash'
import React, { memo, useEffect, useRef, useState } from 'react'

export interface Props {
  bookURL?: string
  description?: string
  guests?: string
  images?: ImageProps[]
  languageCode: string
  size?: string
  title?: string
}

export const ItemMobile = memo(function ItemMobile({
  bookURL,
  description,
  guests,
  images,
  languageCode,
  size,
  title,
}: Props) {
  if (!images) {
    return null
  }

  const containerRef = useRef<any>()
  const [currentSlide, setCurrentSlide] = useState(0)
  const [loaded, setLoaded] = useState<boolean[]>([])
  const [nextLoaded, setNextLoaded] = useState<boolean[]>([])
  const [nextTwoLoaded, setNextTwoLoaded] = useState<boolean[]>([])

  const [sliderRef, galleryRef] = useKeenSlider({
    loop: true,
    drag: false,
    slides: {
      perView: 1.27,
      spacing: 20,
    },
    breakpoints: {
      '(max-width: 768px)': {
        drag: true,
        slides: {
          perView: 1.27,
          spacing: 20,
        },
      },
    },
    slideChanged(s) {
      setCurrentSlide(s.track.details.rel)
    },
  })

  useEffect(() => {
    const newLoaded = [...loaded]
    newLoaded[currentSlide] = true
    const newNextLoaded = [...nextLoaded]
    newNextLoaded[currentSlide + 1] = true
    const newNextTwoLoaded = [...nextLoaded]
    newNextTwoLoaded[currentSlide + 2] = true

    setLoaded(newLoaded)
    setNextLoaded(newNextLoaded)
    setNextTwoLoaded(newNextTwoLoaded)
  }, [currentSlide, galleryRef, sliderRef])

  return (
    <Container>
      <Wrapper row dial={6}>
        <Top>
          <SliderContainer ref={containerRef}>
            <Slider ref={sliderRef} className="keen-slider">
              {uniq(images).map((item, index) => {
                return (
                  <Slide className="keen-slider__slide" key={index}>
                    <Image
                      alt={loaded[index] || nextLoaded[index] ? item.alt : ''}
                      animationStart={
                        loaded[index] ||
                        nextLoaded[index] ||
                        nextTwoLoaded[index]
                          ? true
                          : false
                      }
                      {...(loaded[index] ||
                      nextLoaded[index] ||
                      nextTwoLoaded[index]
                        ? item
                        : null)}
                    />
                  </Slide>
                )
              })}
            </Slider>
            {images.length > 1 ? (
              <Cursor
                container={containerRef}
                gallery={galleryRef}
                hasOffset={true}
              />
            ) : null}
          </SliderContainer>
        </Top>

        <Bottom>
          {title ? (
            <FadeInUp>
              <Title>{title}</Title>
            </FadeInUp>
          ) : null}
          {size || guests ? (
            <FadeInUp>
              <Inner row dial={4}>
                {guests ? (
                  <Guests>
                    <img
                      src="/icon_guests.svg"
                      alt="Ospiti"
                      width="20"
                      height="20"
                    />
                    {guests}
                  </Guests>
                ) : null}
                {size ? (
                  <Size>
                    <img
                      src="/icon_size.svg"
                      alt="Dimensioni"
                      width="20"
                      height="20"
                    />
                    {size}
                  </Size>
                ) : null}
              </Inner>
            </FadeInUp>
          ) : null}
          {description ? (
            <FadeInUp>
              <Description dangerouslySetInnerHTML={{ __html: description }} />
            </FadeInUp>
          ) : null}
          {bookURL ? (
            <FadeInUp>
              <StyledButton
                URL={bookURL}
                label={useVocabularyData('book', languageCode)}
              />
            </FadeInUp>
          ) : null}
        </Bottom>
      </Wrapper>
    </Container>
  )
})

const Container = styled.section`
  position: relative;
  margin-top: 5rem;
`

const Wrapper = styled(FlexBox)`
  margin-left: 1.5625rem;

  @media (max-width: 1199px) {
    flex-direction: column;
  }
`
const Top = styled.div`
  width: 100%;
`

const Bottom = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin-top: 1.875rem;
  margin-right: 1.5625rem;
`

const Title = styled.h2`
  color: ${({ theme }) => theme.colors.variants.primaryDark};
  font-family: ${({ theme }) => theme.fontFamily.heading};
  font-weight: bold;
  font-size: 1.875rem;
  line-height: 2.1875rem;
  text-transform: uppercase;
`

const Inner = styled(FlexBox)`
  margin-top: 0.6875rem;
`

const Guests = styled.div`
  display: flex;
  align-items: center;
  margin-right: 1.3125rem;
  color: ${({ theme }) => theme.colors.variants.primaryDark};
  font-family: ${({ theme }) => theme.fontFamily.paragraph};
  font-weight: bold;
  font-size: 0.625rem;
  letter-spacing: 0.1em;
  line-height: 1.6875rem;
  text-transform: uppercase;

  img {
    margin-right: 0.75rem;
  }
`

const Size = styled.div`
  display: flex;
  align-items: center;
  color: ${({ theme }) => theme.colors.variants.primaryDark};
  font-family: ${({ theme }) => theme.fontFamily.paragraph};
  font-weight: bold;
  font-size: 0.625rem;
  letter-spacing: 0.1em;
  line-height: 1.6875rem;
  text-transform: uppercase;

  img {
    margin-right: 0.75rem;
  }
`

const Description = styled.div`
  margin-top: 0.9375rem;
  color: ${({ theme }) => theme.colors.variants.primaryDark};
  font-family: ${({ theme }) => theme.fontFamily.paragraph};
  font-weight: normal;
  font-size: 0.875rem;
  line-height: 1.375rem;
`

const StyledButton = styled(Button)`
  margin-top: 1.875rem;
`

const SliderContainer = styled.div`
  position: relative;
  overflow: hidden;

  &:hover {
    @media (min-width: 769px) {
      cursor: none;
    }

    @media (max-width: 768px) {
      cursor: move;
      cursor: grab;
      cursor: -moz-grab;
      cursor: -webkit-grab;
    }
  }
`

const Slider = styled.div`
  display: flex;
  height: 57.77vh;
  max-height: 32.5rem;
  overflow: hidden;

  @media (max-width: 768px) {
    max-height: 21.875rem;
  }

  @media (max-width: 480px) {
    max-height: 12.5rem;
  }
`

const Slide = styled.div`
  img {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
`
